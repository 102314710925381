<template>
  <div class="container">
    <h2 class="text-center">Macro Assistant</h2>
    <div
      v-if="isLoading"
      class="d-flex align-items-center justify-content-center"
    >
      <div class="spinner-border mt-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="isLoading" class="text-center">
      This can take up to 60 seconds...
    </div>
    <div v-else class="chat-box">
      <p v-if="response && response.split('\n').length">
        {{ response.split("\n")[0] }}
      </p>
      <ul v-if="response && response.split('\n').length">
        <li
          v-for="(item, index) in response.split('\n').slice(1).filter(Boolean)"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
      <p v-else>
        {{
          "Click get good suggestions to receive some helpful hints on foods you could eat to fill your macros for today..."
        }}
      </p>
    </div>
    <div class="d-flex align-items-center justify-content-center p-2">
      <material-button color="info" @click="onClick()"
        >Get Food Suggestions</material-button
      >
    </div>
  </div>
</template>

<script>
import { foodAssist } from "../../data/ai/food.js";
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "Assistant",
  components: {
    MaterialButton,
  },
  data() {
    return {
      response: null,
      isLoading: false,
    };
  },
  methods: {
    async onClick() {
      this.isLoading = true;
      const response = await foodAssist();
      this.response = response;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.chat-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  height: 600px;
  overflow-y: auto;
  text-align: left;
}

.chat-box ul {
  list-style-type: none;
}
</style>

<template>
  <main class="mt-0 main-content main-content-bg">
    <div class="page-header bg-gradient-info  align-items-start min-height-200 m-3 border-radius-xl bg-gray-200" style="background-size: cover; background-position: center;
      ">
      <span class="mask bg-gradient-dark opacity-4"></span>
    </div>
    <div class="container">
      <div class="row mt-lg-n12 mt-md-n12 mt-n11 justify-content-center pb-2">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card mt-8">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-info shadow-info border-radius-lg py-3 pe-1 text-center py-4">
                <h4 class="font-weight-bolder text-white mt-1">
                  Sign Up
                </h4>
                <p class="mb-1 text-white text-sm">
                  Enter your email and password to register
                </p>
              </div>
            </div>
            <div class="card-body pb-3">
              <form role="form">
                <div class="mb-3">
                  <material-input id="name" v-model="name" label="Name" :required="true"/>
                </div>
                <div class="mb-3">
                  <material-input id="email" v-model="email" type="email" label="Email" autocomplete="username" :required="true" />
                </div>
                <div class="mb-3">
                  <material-input id="password" v-model="password" type="password" label="Password" autocomplete="new-password" :required="true" />
                </div>
                <material-checkbox id="flexCheckDefault" class="font-weight-light" checked required>
                  I agree the
                  <a href="../../../pages/privacy.html" class="text-dark font-weight-bolder">Terms and Conditions</a>
                </material-checkbox>
                <div class="text-center">
                  <div v-if="isLoading" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  <material-button color="info" variant="gradient" full-width class="mt-4 mb-0"
                    :disabled="preventSignUp" @click.prevent="signup">Sign up</material-button>
                </div>
              </form>
            </div>
            <div class="card-footer text-center pt-0 px-sm-4 px-1">
              <p class="mb-4 mx-auto">
                Already have an account?
                <router-link to="/login" class="text-success text-gradient font-weight-bold">Sign in</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialButton from "@/components/MaterialButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import supabase from "../../../data/createClient.js";
import axios from "axios";
import {logError} from "@/errors/rollbar";

export default {
  name: "Signup",
  components: {
    MaterialInput,
    MaterialCheckbox,
    MaterialButton
  },
  props: {
    session: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      isLoading: false,
      preventSignUp: false
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async signup() {
      this.isLoading = true;
      
      if (!this.session) {
        this.$swal.fire({
          icon: 'error',
          title: 'Signup Failed',
          text: 'Please contact support to resolve this issue',
        })
        this.isLoading = false;
        return;
      }
      
      let response = null;
      
      try {
        response = await axios.get(`${process.env.VUE_APP_API_URL}/api/stripe/customer?session=${this.session}`);        
      } catch (error) {
        logError(error);
        this.isLoading = false;
        return;
      }
      
      const hostname = window.location.origin;

      const { error } = await supabase.auth.signUp({
        email: this.email,
        password: this.password,
        options: {
          data: {
            emailRedirectTo: hostname,
            first_name: this.name,
            stripe_session_id: this.session,
            stripe_customer_id: response.data.customer
          }
        }
      })

      this.isLoading = false;

      if (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Signup Failed',
          text: error.message,
        })
      } else {
        this.$swal.fire({
          icon: 'success',
          title: 'Signup Successful',
          text: 'Please check your email for verification',
        })
      }
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Average From Last 4 Weeks</h6>
        <button type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip" data-bs-placement="bottom" title=""
          data-bs-original-title="See how your weight has changed over time">
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-danger"></i>
          <span class="text-xs text-dark">Weight</span>
        </span>
      </div>
    </div>
    <div class="p-3 card-body">
      <default-line-chart id="chart-line3" title="Traffic channels" :chart="{
        labels: this.dates,
        datasets: [
          {
            label: 'Weight',
            data: this.weights,
          }
        ],
      }" />
    </div>
  </div>
</template>

<script>
import DefaultLineChart from "@/examples/Charts/DefaultLineChart.vue";

export default {
  name: "WeightChart",
  components: {
    DefaultLineChart,
  },
  props: {
    userId: {
      type: Number,
      default: 2,
    },
    dates: {
      type: Array
    },
    weights: {
      type: Array

    },
  },
  data() {
    return {
    };
  },
};
</script>

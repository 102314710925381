Number.prototype.toNonFatCaloriePercentage = function (calories) {
    if (isNaN(calories) || calories <= 0) {
      throw new Error('Total calories must be a positive number.');
    }
  
    const percentage = ((this * 4) / calories) * 100;
    return percentage.toFixed(2);
  };

  Number.prototype.toCaloriePercentage = function (calories) {
    if (isNaN(calories) || calories <= 0) {
      throw new Error('Total calories must be a positive number.');
    }
  
    const percentage = ((this * 9) / calories) * 100;
    return percentage.toFixed(2);
  };
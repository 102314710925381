<template>
  <div v-if="!isLoading" class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Weight History</h6>
        <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          data-bs-original-title="See how your weight has changed over time"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-danger"></i>
          <span class="text-xs text-dark">Weight</span>
        </span>
        <!-- <span class="badge badge-md badge-dot me-4">
          <i class="bg-dark"></i>
          <span class="text-xs text-dark">Min</span>
        </span>
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-danger"></i>
          <span class="text-xs text-dark">Max</span>
        </span> -->
      </div>
    </div>
    <div class="p-3 card-body">
      <default-line-chart
        id="chart-line3"
        title="Traffic channels"
        :chart="{
          labels: this.dates,
          datasets: [
            {
              label: 'Weight',
              data: this.weights,
            },
          ],
        }"
      />
    </div>
  </div>
</template>

<script>
import DefaultLineChart from "@/examples/Charts/DefaultLineChart.vue";
import supabase from "../../../data/createClient";

export default {
  name: "RevenueChartCard",
  components: {
    DefaultLineChart,
  },
  props: {
    userId: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      dates: [],
      weights: [],
    };
  },
  async mounted() {
    await this.getWeights();
  },
  methods: {
    async getWeights() {
      if (!this.userId) return console.error("No user id provided");

      try {
        const { data, status } = await supabase.rpc("fourweeksweightdata", {
          _id: this.userId,
        });

        if (status != 200) {
          console.error("Error fetching data");
        } else {
          this.data = data;
          this.dates = data.map((item) => item.date);
          this.weights = data.map((item) => item.weight);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

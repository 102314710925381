<template>
  <div>
    <vue-advanced-chat
      height="calc(80vh)"
      :current-user-id="currentUserId"
      :room-info-enabled="true"
      :rooms="JSON.stringify(rooms)"
      :rooms-loaded="true"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :show-audio="false"
      :show-reaction-emojis="false"
      @room-info="roomInfo($event.detail)"
      @send-message="sendMessage($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0], $event.detail[1])"
      @add-room="addRoom()"
      @delete-message="deleteMessage($event.detail)"
      @edit-message="editMessage($event.detail)"
    />
  </div>
</template>

<script>
import { register } from "vue-advanced-chat";
register();
import supabase from "../../data/createClient.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      currentUserId: "",
      currentUsername: "",
      /**
       * Rooms data structure:
       * @type {Array<{roomId: string, roomName: string, users: Array}>}
       */
      rooms: [],
      /**
       * Users data structure:
       * @type {Array<{_id: string, username: string}>}
       */
      users: [],
      menuActions: [
        {
          name: "inviteUser",
          title: "Invite User",
        },
        {
          name: "removeUser",
          title: "Remove User",
        },
        {
          name: "deleteRoom",
          title: "Delete Room",
        },
      ],

      messages: [
        {
          _id: "491dc15e-81aa-4316-833d-753d6b214f9f",
          content: "Hello",
          senderId: "1234",
          username: "Aaron Day",
          date: "13 November",
          timestamp: "10:20",
        },
      ],
      messagesLoaded: false,
    };
  },
  computed: {
    ...mapState(["name"]),
  },
  async created() {
    await this.getAllData();
  },
  methods: {
    async getAllData() {
      const { data: usersession } = await supabase.auth.getSession();
      this.currentUserId = usersession.session.user.id;
      this.currentUsername = this.name;
      let rooms = [];

      await supabase
        .channel("custom-all-channel")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "chat_messages" },
          (payload) => {
            payload.new.date = new Date(payload.new.timestamp).toDateString();
            payload.new.timestamp = new Date(payload.new.timestamp)
              .toString()
              .substring(16, 21);
            this.messages = [...this.messages, payload.new];
          }
        )
        .subscribe();

      const { data: roomsData, error } = await supabase
        .from("chat_rooms")
        .select();

      if (error || !roomsData || roomsData.length === 0) {
        console.error("Error fetching messages", error);
        const dummyRoom = [
          {
            roomId: "1",
            roomName: "No rooms",
            users: null,
          },
        ];
        this.rooms = dummyRoom;
        return;
      }

      const { data: users, error: userError } = await supabase
        .from("chat_users")
        .select();

      if (userError || !users || users.length === 0) {
        console.error("Error fetching messages", error);
        return;
      }

      this.currentUsername = users.find(
        (user) => user.user_id === this.currentUserId
      ).username;

      for (let i = 0; i < roomsData.length; i++) {
        rooms.push({
          roomId: roomsData[i].id,
          roomName: roomsData[i].room_name,
          users: roomsData[i].users
            ? roomsData[i].users.map((user) => {
                return {
                  _id: user,
                  username: users.find((u) => u.user_id === user).username,
                };
              })
            : null,
        });
      }

      this.rooms = rooms;
    },
    async fetchMessages({ room, options }) {
      console.log("fetchMessages", room, options);
      const { data: messages, error: messageError } = await supabase
        .from("chat_messages")
        .select()
        .eq("room_id", room.roomId)
        .order("timestamp", { ascending: true });

      if (!messages || messages.length === 0 || messageError) {
        console.log("No messages found", messageError);
        // add a dummy message to say there are no messages
        let dummyMessage = [
          {
            _id: 1,
            content: "No messages",
            senderId: "491dc15e-81aa-4316-833d-753d6b214f9f",
            system: true,
            date: new Date().toDateString(),
            timestamp: new Date().toString().substring(16, 21),
          },
        ];
        this.messages = dummyMessage;
        this.messagesLoaded = true;
        return;
      }

      messages.forEach((message) => {
        message.date = new Date(message.timestamp).toDateString();
        message.timestamp = new Date(message.timestamp)
          .toString()
          .substring(16, 21);
      });

      await supabase
        .channel("custom-all-channel")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "chat_messages" },
          (payload) => {
            payload.new.date = new Date(payload.new.timestamp).toDateString();
            payload.new.timestamp = new Date(payload.new.timestamp)
              .toString()
              .substring(16, 21);
            this.messages = [...this.messages, payload.new];
          }
        )
        .subscribe();

      this.messages = messages;
      this.messagesLoaded = true;
    },

    async sendMessage(message) {
      await supabase.from("chat_messages").insert([
        {
          content: message.content,
          senderId: this.currentUserId,
          timestamp: new Date(),
          room_id: message.roomId,
          username: this.currentUsername,
        },
      ]);
    },

    async editMessage(detail) {
      if (detail.length === 0) return;

      const message = detail[0];

      await supabase
        .from("chat_messages")
        .update({ content: message.newContent })
        .eq("_id", message.messageId);
    },

    async deleteMessage(detail) {
      if (detail.length === 0) return;

      const message = detail[0].message;

      await supabase
        .from("chat_messages")
        .update({ deleted: true })
        .eq("_id", message._id);
    },

    async addRoom() {
      //set an alert to notify that this feature is not available
      this.$swal.fire({
        title: "Not available",
        text: "This is currently not available. Please check back later.",
        icon: "info",
        confirmButtonText: "OK",
      });

      //   const { data: users, error: userError } = await supabase
      //     .from("chat_users")
      //     .select();

      //   if (userError || !users || users.length === 0) {
      //     console.error("Error fetching messages", userError);
      //     return;
      //   }

      //   const testUsers = users.map((user) => {
      //     return {
      //       _id: user.user_id,
      //       username: user.username,
      //     };
      //   });

      //   const { data: newRoom } = await supabase
      //     .from("chat_rooms")
      //     .insert([
      //       { room_name: "New Room", users: testUsers.map((user) => user._id) },
      //     ])
      //     .select();

      //   this.rooms = [
      //     ...this.rooms,
      //     {
      //       roomId: newRoom.id,
      //       roomName: "New Room",
      //       users: testUsers,
      //     },
      //   ];
    },
    roomInfo(room) {
      console.log("roomInfo", room);
    },
  },
};
</script>
